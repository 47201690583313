export default {
  tooltip: {
    color: "#f1f1f1",
    rippleBackgroundColor: "blue",
    fontSize: 16
  },
  palette: {
    primary: {
      light: "#e5337c",
      main: "#e91866",
      dark: "#e5035f",
      contrastText: "#fff"
    },
    secondary: {
      main: "#333",
      contrastText: "#fff"
    },
    success: {
      main: "#388e3c",
      contrastText: "#fff"
    },
    info: {
      main: "#1e88e5",
      contrastText: "#fff"
    },
    warning: {
      main: "#f9a825",
      contrastText: "#fff"
    },
    error: {
      main: "#b71c1c",
      contrastText: "#fff"
    },
    magenta: {
      main: "#e20074",
      contrastText: "#fff"
    },
    autofill: {
      background: "#fffde7"
    }
  },
  status: {
    danger: "orange"
  },
  typography: {
    useNextVariants: true,
    //fontSize: 13,
    h5: {
      fontSize: "1.15rem"
    },
    button: {
      fontWeight: 400,
      textAlign: "capitalize"
    }
  },
  header: {
    color: "default",
    iconColor: "primary",
    logoMargin: 12
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: "0px!important"
      },
      labelContainer: {
        padding: "6px 12px!Important"
      }
    }
  }
};
